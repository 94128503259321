:root {
  --arrow-right: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.881' height='18.083'%3E%3Cpath d='m1.083 1.041 7.717 8-7.717 8' fill='none' stroke='%2300dcff' stroke-width='3'/%3E%3C/svg%3E");
}

@keyframes splide-loading {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.splide__track--fade > .splide__list {
  display: block;
}

.splide__track--fade > .splide__list > .splide__slide {
  opacity: 0;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
  position: relative;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  height: 100%;
  display: flex;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  pointer-events: none;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

.splide__pagination li {
  pointer-events: auto;
  margin: 0;
  line-height: 1;
  list-style-type: none;
  display: inline-block;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  visibility: hidden;
  position: relative;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  margin: 0;
  position: relative;
  list-style-type: none !important;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  contain: strict;
  height: 20px;
  width: 20px;
  border: 2px solid #999;
  border-left-color: #0000;
  border-radius: 50%;
  margin: auto;
  animation: splide-loading 1s linear infinite;
  display: inline-block;
  position: absolute;
  inset: 0;
}

.splide__sr {
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.splide__toggle__pause, .splide__toggle.is-active .splide__toggle__play {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  z-index: 0;
  position: relative;
  overflow: hidden;
}

:root {
  --arrow: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 37.456 12.188' fill='none' stroke='%23fff' stroke-width='2'%3E%3Cpath data-name='Path 24' d='M37.455 6.052H2.064'/%3E%3Cpath data-name='Path 25' d='M6.801.707 1.414 6.094l5.387 5.387'/%3E%3C/svg%3E");
}

.splide__arrow {
  width: 37.456px;
  height: 12.188px;
  background-color: #0000;
  background-image: var(--arrow);
  opacity: .25;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  transition: opacity .2s;
}

.splide__arrow:hover {
  opacity: 1;
}

.splide__arrow--next {
  transform: rotate(180deg);
}

.splide__pagination {
  padding: 0 1rem;
}

.splide__pagination__page {
  width: .875rem;
  height: .875rem;
  background-color: #fff;
  border: none;
  border-radius: 500px;
  margin: 0 4px;
  display: block;
  position: relative;
}

.splide__pagination__page.is-active:after {
  content: "";
  background-color: #237ff8;
  border-radius: 500px;
  position: absolute;
  inset: .1875rem;
}

/*# sourceMappingURL=splide.css.map */

@import "partials/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";

// Default theme
@import "~@splidejs/splide/src/css/core/index";

:root {
  --arrow: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 37.456 12.188' fill='none' stroke='%23fff' stroke-width='2'%3E%3Cpath data-name='Path 24' d='M37.455 6.052H2.064'/%3E%3Cpath data-name='Path 25' d='M6.801.707 1.414 6.094l5.387 5.387'/%3E%3C/svg%3E");
}

// Arrows
.splide__arrow {
  background-color: transparent;
  border: none;
  width: 37.456px;
  height: 12.188px;
  background-image: var(--arrow);
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.25;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &--next {
    transform: rotate(180deg);
  }
}

// Pagination
.splide__pagination {
  padding: 0 1rem;

  &__page {
    display: block;
    width: 0.875rem;
    height: 0.875rem;
    background-color: $white;
    border-radius: 500px;
    border: none;
    margin: 0 4px;
    position: relative;

    &.is-active::after {
      content: "";
      position: absolute;
      inset: 0.1875rem;
      background-color: $info;
      border-radius: 500px;
    }
  }
}

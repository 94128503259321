:root {
  --arrow-right: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.881' height='18.083'%3E%3Cpath d='m1.083 1.041 7.717 8-7.717 8' fill='none' stroke='%2300dcff' stroke-width='3'/%3E%3C/svg%3E");
}

// Colors
$header-color: #332967;
$text-color: #505050;

$primary: #0d0939;
$secondary: #00DCFF;
$light: #F5F6F9;
// $success: #c2ba93;
$danger: #EE707D;
$info: #237FF8;
// $warning: #fdf3ee;
// $gray: #707070;
$dark: #332967;

$text-muted: #505050;
$link-decoration: none;
$link-color: $dark;
$link-hover-color: $secondary;

// Gradients
$gradient-color-1: #6640d1;
$gradient-color-2: #36a0e6;
$gradient: linear-gradient(to right, $gradient-color-1, $gradient-color-2);
$gradient-y: linear-gradient(to top, $gradient-color-1, $gradient-color-2);

// Font weight
$font-weight-lighter: 300;
$font-weight-light: 400;
$font-weight-normal: 500;
$font-weight-bold: 600;
$font-weight-bolder: 700;

$form-check-input-checked-color: $secondary;
$form-check-input-checked-bg-color: transparent;
$form-check-input-focus-box-shadow: #332967;

$custom-spacers: (
  row: 0.75rem,
  1: 3.5rem,
  2: 4rem,
  3: 4.5rem,
  4: 5rem,
  5: 5.5rem,
  6: 6rem,
  7: 6.5rem,
  8: 7rem,
  9: 7.5rem,
  10: 8rem,
  11: 12rem,
);

$font-size-base: .875rem;

$font-sizes: (
  1: 3.75rem,
  2: 2.5rem,
  3: 2rem,
  4: 1.5rem,
  5: 1.25rem,
  6: 1rem,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  // xxl: 1320px
);

// Custom classses
$utilities: (
  "c-font-size": (
    property: font-size,
    class: "c-fs",
    responsive: true,
    rfs: true,
    values:
      map-merge(
        $font-sizes,
        (
          "small": .875rem,
        )
      ),
  ),
  "c-gap": (
    class: c-gap,
    property: gap,
    responsive: true,
    values: $custom-spacers,
  ),
  // Margins utilities
  "c-margin":
    (
      responsive: true,
      property: margin,
      class: c-m,
      values: $custom-spacers,
    ),
  "c-margin-x": (
    responsive: true,
    property: margin-right margin-left,
    class: c-mx,
    values: $custom-spacers,
  ),
  "c-margin-y": (
    responsive: true,
    property: margin-top margin-bottom,
    class: c-my,
    values: $custom-spacers,
  ),
  "c-margin-top": (
    responsive: true,
    property: margin-top,
    class: c-mt,
    values: $custom-spacers,
  ),
  "c-margin-end": (
    responsive: true,
    property: margin-right,
    class: c-me,
    values: $custom-spacers,
  ),
  "c-margin-bottom": (
    responsive: true,
    property: margin-bottom,
    class: c-mb,
    values: $custom-spacers,
  ),
  "c-margin-start": (
    responsive: true,
    property: margin-left,
    class: c-ms,
    values: $custom-spacers,
  ),
  // Padding utilities
  "c-padding":
    (
      responsive: true,
      property: padding,
      class: c-p,
      values: $custom-spacers,
    ),
  "c-padding-x": (
    responsive: true,
    property: padding-right padding-left,
    class: c-px,
    values: $custom-spacers,
  ),
  "c-padding-y": (
    responsive: true,
    property: padding-top padding-bottom,
    class: c-py,
    values: $custom-spacers,
  ),
  "c-padding-top": (
    responsive: true,
    property: padding-top,
    class: c-pt,
    values: $custom-spacers,
  ),
  "c-padding-end": (
    responsive: true,
    property: padding-right,
    class: c-pe,
    values: $custom-spacers,
  ),
  "c-padding-bottom": (
    responsive: true,
    property: padding-bottom,
    class: c-pb,
    values: $custom-spacers,
  ),
  "c-padding-start": (
    responsive: true,
    property: padding-left,
    class: c-ps,
    values: $custom-spacers,
  ),
  "c-max-width": (
    responsive: true,
    property: max-width,
    class: c-mw,
    values: (
      300: 300,
      320: 320,
      420: 420,
      480: 480,
    ),
  ),
  // "c-min-height": (
  //   responsive: true,
  //   property: min-height,
  //   class: c-min-h,
  //   values: (
  //     380: 380px,
  //   ),
  // ),
  // NEGATIVE SPACERS
  "negative-margin-top": (
      responsive: true,
      property: margin-top,
      class: -c-mt,
      values: (
        5: -5rem,
      )
    ),
);

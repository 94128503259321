.splide {
  $root: &;

  &__track--fade {
    > #{ $root }__list {
      display: block;

      > #{ $root }__slide {
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        z-index: 0;

        &.is-active {
          opacity: 1;
          position: relative;
          z-index: 1;
        }
      }
    }
  }
}
